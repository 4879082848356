<template>
  <v-bottom-navigation
    v-if="$vuetify.breakpoint.smAndDown && showMobileNav"
    class="align-center no-print"
    color="primary"
    app
    grow
  >
    <v-btn
      :to="{ name: 'home' }"
      class="footer-buttons"
    >
      <span>{{ $t("menu.shop") }}</span>
    </v-btn>
    <v-btn
      v-if="$auth.isAuthenticated && $auth.user.email_verified"
      :to="{ name: 'bookshelf' }"
      class="footer-buttons"
    >
      <span>{{ $t("menu.bookshelf") }}</span>
    </v-btn>
    <v-btn
      v-if="$auth.isAuthenticated"
      :to="{ name: 'userProfile' }"
      class="footer-buttons"
    >
      <span>{{ $t("menu.profile") }}</span>
    </v-btn>
    <v-btn
      :to="{ name: 'schoolBookLists' }"
      class="footer-buttons"
    >
      <span style="line-clamp: 2">
        {{ $t("menu.schoolBookLists") }}
      </span>
    </v-btn>
    <login-link
      v-if="!$auth.isAuthenticated"
      class="v-btn"
    >
      <v-btn class="footer-buttons">
        <span>{{ $t("menu.login") }}</span>
      </v-btn>
    </login-link>
    <login-link
      v-if="!$auth.isAuthenticated"
      sign-up
      class="v-btn"
    >
      <v-btn class="footer-buttons">
        <span>{{ $t("menu.register") }}</span>
      </v-btn>
    </login-link>
  </v-bottom-navigation>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import LoginLink from "@/components/LoginLink";

export default {
  name: "MobileNav",
  components: {
    LoginLink,
  },
  computed: {
    ...mapGetters("siteSettings", ["showMobileNav"]),
  },
};
</script>

<style scoped lang="scss">
@import "~vuetify/src/styles/styles.sass";

.v-btn.footer-buttons {
  height: 100%;
}
</style>

