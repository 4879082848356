<template>
  <div>
    <apollo-error-alert 
      v-if="error"
      class="mt-3"
      :error="error"
    />
    <v-container>
      <v-row>
        <book-list-item
          v-for="book in data"
          :id="book.id"
          :key="book.id"
          :price="book.price"
          :thumbnail-url="book.thumbnailUrl"
          :title="book.title"
          :currency="book.currency"
          :is-owned="checkInBookshelf(book.id) != null && showOwned"
          :format="book.format"
          :read-url="checkInBookshelf(book.id)?.readUrl"
          :show-buy-now="showBuyNow"
          :is-owned-loading="bookshelfLoading"
        />
        <v-col
          v-for="n in showLoadingItems(isLoading)"
          :key="n"
          class="col-4 col-sm-3 col-md-2 pa-1 pa-sm-2 pa-md-3"
        >
          <v-skeleton-loader
            type="card"
          />
        </v-col> 
      </v-row>
    </v-container>
    <div
      v-if="!hasMore && data?.length > defaultPageSize && !isLoading"
      class="mx-auto"
    >
      <div class="mt-3 d-flex flex-column align-center">
        <h4>{{ $t('shop.endOfCategory') }}</h4>
        <h3 class="mt-3">
          {{ $t('shop.backToTop') }}
        </h3>
        <v-btn
          color="secondary"
          fab
          @click="goToTop"
        >
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
      </div>
    </div>
    <div
      v-if="data && hasMore && data.length >= defaultPageSize && !isLoading"
      v-intersect.once="() => loadMore(query)"
    />
    <v-progress-circular
      v-if="(data && data.length > 0) && isLoading"
      indeterminate
      style="width: 100%;"
      color="primary"
    />
  </div>
</template>

<script>
import BookListItem from "@/components/bookList/BookListItem";
import ApolloErrorAlert from "@/components/ApolloErrorAlert";
import loadBooksMixin from '@/mixins/loadBooksMixin';
import BookshelfMixin from "@/mixins/BookshelfMixin";

export default {
  name: "BookDisplay",
  components: {
    BookListItem,
    ApolloErrorAlert,
  },
  mixins: [
    loadBooksMixin,
    BookshelfMixin,
  ],
  props: {
    query: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Object,
      default: null,
    },
    loadingItems: {
      type: Number,
      default: 12,
    },
    paginationEnabled: {
      type: Boolean,
      default: true,
    },
    showOwned: {
      type: Boolean,
      default: true,
    },
    showBuyNow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1,
      hasMore: this.paginationEnabled,
      loadingItemsCount: this.loadingItems,
    };
  },
  computed: {
    bookshelfLoading() {
      return this.$apollo.queries.books.loading;
    },
  },
  methods: {
    goToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
}
</script>