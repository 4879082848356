<template>
  <div>
    <v-row
      v-if="$apollo.queries.book.loading"
    >
      <v-col
        class="d-flex" 
      >
        <v-skeleton-loader
          type="image"
          class="book-cover-image mr-3"
        />
        <div class="d-flex flex-column flex-grow-1">
          <v-skeleton-loader
            type="heading"
            class="mb-2"
          />
          <v-skeleton-loader
            type="text"
            style="max-width: 400px;"
          />
          <v-skeleton-loader
            type="heading"
            class="mt-auto"
            style="max-width: 200px;"
          />
        </div>
      </v-col>
    </v-row>
    <apollo-error-alert
      v-else-if="bookError"
      :error="bookError"
    />
    <v-row v-else>
      <v-col cols="auto">
        <v-img
          class="book-cover-image"
          :src="book.thumbnailUrl"
        >
          <template #placeholder>
            <image-loading />
          </template>
        </v-img>
      </v-col>
      <v-col class="details-column mr-3">
        <h2 class="book-title">
          {{ decodeHtml(orderLine.name) }}
        </h2>
        <div v-if="book.authors && book.authors.length > 0">
          <v-row no-gutters>
            <h3 class="book-info__author mr-1">
              {{ $t('bookInfo.by') }}
            </h3>
            <h3
              v-for="(author, index) in authorList"
              :key="author"
              class="book-info__author mr-1"
            >
              {{ index + 1 == authorList.length ? decodeHtml(author) : $t('bookInfo.authorListItem', {author: decodeHtml(author)}) }}
            </h3>
            <h3
              v-if="remainingAuthorsCount > 0"
              class="book-info__author"
            >
              {{ $t('bookInfo.moreAuthors', {count: remainingAuthorsCount}) }}
            </h3>
          </v-row>
        </div>
        <h4
          v-else
          class="book-info__author"
        >
          {{ $t("bookInfo.authorUnknown") }}
        </h4>
        <div
          v-if="orderLine?.format == formats.print"
          class="mt-1 d-flex flex-column flex-sm-row justify-start align-start"
        >
          <v-chip
            class="rounded-0 mr-sm-3"
            color="primary"
            label
          >
            <v-icon class="mr-3 mb-1">
              mdi-book-open-page-variant
            </v-icon>
            <h3>{{ $t('formats.'+orderLine.format) }}</h3>
          </v-chip>
          <order-line-quantity-input
            v-if="showQuantityInput"
            class="my-2 mt-sm-0"
            :order-line-id="orderLine.id"
            :quantity="orderLine.quantity"
          />
        </div>
        <div
          class="mt-auto"
        >
          <div
            v-if="showPrice && orderCurrency"
            class="d-flex"
          >
            <price
              :class="'mt-auto book-price mr-2 '+(hasDiscount ? ' striked-text ' : '')"
              :book-price="Number(orderLine.preTaxPrice * orderLine.quantity)"
              :base-currency="orderCurrency._id"
              include-tax
              should-round
            />
            <price
              v-if="hasDiscount"
              class="mt-auto book-price discount-text"
              :book-price="Number(orderLine.price)"
              :base-currency="orderCurrency._id"
            />
          </div>
          <h5
            v-if="hasDiscount"
            class="discount-text"
          >
            {{ $t('checkout.hasCoupon') }}
          </h5>
        </div>
      </v-col>
    </v-row>    
  </div>
</template>

<script>
import { decodeHtml } from "@/helpers/generalFunctions";
import Price from "@/components/Price";
import ImageLoading from '@/components/ImageLoading';
import gql from "graphql-tag";
import ApolloErrorAlert from "@/components/ApolloErrorAlert.vue";
import { FORMATS } from "@/utils/Const";
import OrderLineQuantityInput from "@/components/inputs/OrderLineQuantityInput.vue";

export default {
  name: "OrderBookDisplayItem",
  components: {
    Price,
    ImageLoading,
    ApolloErrorAlert,
    OrderLineQuantityInput,
  },
  props: {
    orderLine: {
      type: Object,
      required: true,
    },
    orderCurrency: {
      type: Object,
      default: null,
    },
    showPrice: {
      type: Boolean,
      default: true,
    },
    showQuantityInput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      book: {},
      authorsShownCount: 2,
      bookError: null,
    };
  },
  computed: {
    authorList() {
      return this.book.authors.slice(0, this.authorsShownCount);
    },
    remainingAuthorsCount() {
      return this.book.authors.length - this.authorList.length;
    },
    hasDiscount() {
      return this.orderLine.discount && this.orderLine.discount > 0;
    },
    formats() {
      return FORMATS;
    },
  },
  apollo: {
    book: {
      query: gql`
        query Book($id: ID!) {
          book(id: $id) {
            id
            thumbnailUrl
            authors
          }
        }
      `,
      variables() {
        return {
          id: "/books/" + this.orderLine.productId,
        };
      },
      error(error) {
        this.bookError = error;
      },
    },
  },
  methods: {
    decodeHtml,
  }
};
</script>

<style scoped>
.book-cover-image {
  width: 90px;
  height: 135px;
}

.details-column {
  display: flex;
  flex-direction: column;
}

.book-title {
  font-size: 1.25rem;
  font-weight: 500;
}

.book-info__author {
  font-size: 0.875rem;
  font-weight: 500;
}

.book-price {
  font-size: 1.25rem;
  font-weight: 500;
}

.discount-text {
  color: var(--v-success-base);
}

.striked-text {
  text-decoration: line-through;
}

@media screen and (max-width: 899px) {
  .book-cover-image {
    width: 80px;
    height: 120px;
  }

  .book-title {
    font-size: 1rem;
  }

  .book-info__author {
    font-size: 0.75rem;
  }

  .book-price {
    font-size: 1rem;
  }
}

@media screen and (max-width: 599px) {
  .book-cover-image {
    width: 75px;
    height: 112px;
  }

  .book-title {
    font-size: 0.875rem;
  }

  .book-info__author {
    font-size: 0.625rem;
  }

  .book-price {
    font-size: 0.875rem;
  }
}
</style>
