<template>
  <v-container>
    <success-snackbar
      v-model="snackbar"
      :message="snackbarMessage"
    />
    <error-snackbar
      v-model="cartError"
      :error-message="$t('shop.orderAlreadyCompleted')"
    />
    <ApolloQuery
      :query="(gql) => getOrderQuery"
      :variables="{ orderId }"
      :skip="!orderId"
      :update="(data) => updateCart(data)"
    >
      <template #default="{ result: { data: order, error }, isLoading, query }">
        <div class="d-flex justify-space-between align-center">
          <h1 class="page-name">
            <v-icon
              class="basket-icon"
              color="var(--v-primary-base)"
            >
              $basket
            </v-icon>
            {{ $t("checkout.cart") }}
          </h1>
          <div class="d-flex flex-column flex-sm-row">
            <empty-cart-button
              :order-id="orderId ?? ''"
              :is-disabled="!orderId || isLoading == 1 || order?.orderLines?.edges?.length == 0"
              @onRemoved="(event) => setSnackbarMessage(event)"
            />
            <v-btn
              v-if="$auth.isAuthenticated"
              tile
              color="primary"
              class="mt-2 mt-sm-0 ml-sm-3 checkout-button"
              elevation="0"
              :small="$vuetify.breakpoint.xs"
              @click="checkout(query)"
            >
              {{ $t('checkout.checkout') }}
              <v-icon
                size="18"
                class="ml-2"
              >
                mdi-arrow-right
              </v-icon>
            </v-btn>
            <v-btn
              v-else
              tile
              color="primary"
              class="mt-2 mt-sm-0 ml-sm-3 checkout-button"
              elevation="0"
              :small="$vuetify.breakpoint.xs"
              @click="login"
            >
              {{ $t('checkout.loginAndCheckout') }}
              <v-icon
                size="18"
                class="ml-2"
              >
                mdi-arrow-right
              </v-icon>
            </v-btn>
          </div>
        </div>
        <apollo-error-alert
          v-if="error"
          :error="error"
        />
        <v-list v-else-if="isLoading">
          <v-list-item
            v-for="n in loadingItemsCount"
            :key="n"
          >
            <v-row class="my-1">
              <v-col class="col-2">
                <v-skeleton-loader type="image" />
              </v-col>
              <v-col>
                <v-skeleton-loader type="article" />
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
        <div v-if="orderId && order && order.orderLines.edges.length > 0 && !order.confirmed">
          <v-list class="mb-9">
            <v-list-item
              v-for="orderLine in order.orderLines.edges.map((edge) => edge.node)"
              :key="orderLine.id"
              class="py-4 px-0"
            >
              <cart-list-item
                :order-line="orderLine"
                :order-id="order.id"
                :order-currency="order.currency"
                :show-quantity-input="true"
                @onRemoved="updateCache"
              />
            </v-list-item>
          </v-list>
          <v-sheet
            class="ma-n6 pa-6"
            color="lightestAccent"
          >
            <div class="d-flex flex-column flex-sm-row align-sm-center">
              <h2 class="summary-header">
                {{ $t("checkout.orderSummary") }}
              </h2>
              <apply-coupon
                v-if="!order.orderCoupon || true"
                :existing-coupon-code="order?.orderCoupon?.code"
                class="ml-0 ml-sm-auto mt-3 mt-sm-0"
                :order-id="orderId"
                @snackbarMessage="setSnackbarMessage"
              />
            </div>
            <div class="order-summary">
              <table class="order-table">
                <tr
                  v-if="order.orderCoupon"
                  class="coupon-header"
                >
                  <td>
                    <div class="d-flex align-center">
                      <span>{{ $t('checkout.hasCoupon') }}</span>
                      <remove-coupon
                        class="ml-3"
                        :order-id="orderId"
                        @snackbarMessage="setSnackbarMessage"
                      />
                    </div>
                  </td>
                  <td
                    class="price-data"
                  >
                    <span>{{ order.orderCoupon.code }}</span>
                    <div style="font-weight: normal">
                      {{ order.orderCoupon.description }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("checkout.itemsTotal") }}</td>
                  <td class="price-data">
                    <price
                      :book-price="Number(order.totalLineItemsPrice)"
                      :base-currency="order.currency._id"
                      :show-free-text="false"
                    />
                  </td>
                </tr>
                <tr
                  v-if="order.totalDiscount > 0"
                  class="discount-text"
                >
                  <td>{{ $t("checkout.discount") }}</td>
                  <td class="price-data">
                    <price
                      :book-price="Number(order.totalDiscount)"
                      :base-currency="order.currency._id"
                      :show-free-text="false"
                    />
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("checkout.subtotal") }}</td>
                  <td class="price-data">
                    <price
                      :book-price="Number(order.subtotalPrice)"
                      :base-currency="order.currency._id"
                      :show-free-text="false"
                    />
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("checkout.vat") }}</td>
                  <td class="price-data">
                    <price
                      :book-price="Number(order.totalTax)"
                      :base-currency="order.currency._id"
                      :show-free-text="false"
                    />
                  </td>
                </tr>
                <tr
                  style="padding: 10px;"
                  class="checkout-total"
                >
                  <td>
                    {{ $t("checkout.total") }}
                  </td>
                  <td class="price-data">
                    <price
                      :book-price="Number(order.totalPrice)"
                      :base-currency="order.currency._id"
                    />
                  </td>
                </tr>
              </table>
            </div>
          </v-sheet>
          <v-container>
            <v-row
              class="button-container"
              justify="end"
            >
              <v-btn
                v-if="$auth.isAuthenticated"
                tile
                color="primary"
                class="checkout-button"
                elevation="0"
                :block="$vuetify.breakpoint.xs"
                :small="$vuetify.breakpoint.xs"
                @click="checkout(query)"
              >
                {{ $t('checkout.checkout') }}
                <v-icon
                  size="18"
                  class="ml-2"
                >
                  mdi-arrow-right
                </v-icon>
              </v-btn>
              <v-btn
                v-else
                tile
                color="primary"
                class="checkout-button"
                @click="login"
              >
                {{ $t('checkout.loginAndCheckout') }}
                <v-icon
                  size="18"
                  class="ml-2"
                >
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </v-row>
          </v-container>
        </div>
        <v-container
          v-else
          justify="center"
        >
          <div class="empty-cart-container">
            <book-list-icon />
            <h1 class="mt-4">
              {{ $t('shop.cartEmpty') }}
            </h1>
          </div>
        </v-container>
      </template>
    </ApolloQuery>
  </v-container>
</template>

<script>
import SuccessSnackbar from "@/components/SuccessSnackbar";
import currencyMixins from "@/mixins/currencyMixins";
import BookListIcon from '@/components/icons/BookListIcon';
import CartListItem from '@/views/checkout/Cart/CartListItem'
import ApolloErrorAlert from '@/components/ApolloErrorAlert';
import ErrorSnackbar from '@/components/ErrorSnackbar';
import Price from '@/components/Price';
import ApplyCoupon from '@/components/coupons/ApplyCoupon';
import RemoveCoupon from '@/components/coupons/RemoveCoupon';
import CouponMutations from "@/graphql/CouponMutations";
import EmptyCartButton from "./EmptyCartButton";
import gql from "graphql-tag";
import OrderFragments from "@/graphql/OrderFragments";

var images = require.context("@/assets/img/", false, /\.jpeg$/);

const ORDER_DETAILS_FRAGMENT = OrderFragments.orderDetailsFragment;

export default {
  name: "CartScreen",
  components: {
    CartListItem,
    SuccessSnackbar,
    BookListIcon,
    ApolloErrorAlert,
    ErrorSnackbar,
    Price,
    ApplyCoupon,
    RemoveCoupon,
    EmptyCartButton,
  },
  mixins: [currencyMixins],
  data() {
    return {
      snackbar: false,
      snackbarMessage: "",
      loadingItemsCount: 6,
      cartError: false,
    };
  },
  computed: {
    getOrderQuery() {
      return gql`
        query GetOrder($orderId: ID!) {
          order(id: $orderId) {
            ...OrderDetails
          }
        }
        ${ORDER_DETAILS_FRAGMENT}
      `;
    },
    orderId() {
      return this.$store.state.cart.currentCart;
    },
  },
  methods: {
    imgUrl: function (path) {
      return images("./" + path);
    },
    async login() {
      await this.$auth.loginWithRedirect({ appState: { targetUrl: "/checkout" } });
    },
    updateCart(data) {
      // remove coupon on empty cart
      if (data?.order?.orderLines?.edges?.length == 0 && data?.order?.orderCoupon) {
        this.$apollo.mutate({
          mutation: CouponMutations.removeCoupon,
          variables: {
            id: data.order.id,
          },
          update: () => {
            this.$apollo.query({
              query: this.getOrderQuery,
              variables: {
                orderId: this.orderId,
              },
              fetchPolicy: "network-only",
            });
          },
        })
      }
      if (!data) {
        this.$store.commit('setCurrentCart', null);
      }

      return data.order;
    },
    async checkout(query) {
      const result = await query.refetch();

      if (result && result.data.order.confirmed) {
        this.cartError = true;
      }
      else {
        this.$router.push({ name: "checkout" });
      }
    },
    setSnackbarMessage(value) {
      this.snackbarMessage = value;
      this.snackbar = true;
    },
    updateCache() {
      this.$apollo.query({
        query: this.getOrderQuery,
        variables: {orderId: this.orderId},
        fetchPolicy: "network-only",
      });

      this.setSnackbarMessage(this.$t("checkout.itemRemoved"));
    },
  },
};
</script>

<style scoped>
h1.page-name .v-icon {
  position: relative;
  top: -2px;
}

.v-list-item {
  border-top: 1px solid var(--v-lightAccent-base);
}

.basket-icon {
  height: 26px;
  width: 26px;
}

.summary-header {
  font-size: 1.375rem;
  font-weight: 500;
  text-transform: uppercase;
}

.order-summary {
  border-top: 1px solid var(--v-lightAccent-base);
  padding-top: 10px;
  margin-top: 12px;
}

.order-table {
  width: 100%;
}

.order-table tr:first-of-type {
  margin-top: 10px;
}

.order-table td {
  padding: 6px 10px;
  font-size: 0.875rem;
}

.checkout-total {
  background-color: var(--v-lightAccent-base);
  padding: 10px;
}

.checkout-total td {
  padding: 10px;
  font-weight: 500;
  line-height: 24px;
  font-size: 1rem;
  text-transform: uppercase;
}

.price-data {
  text-align: right;
}

.button-container {
  padding: 48px 0px 24px;
}

.checkout-button {
  font-size: 1rem !important;
}

.empty-cart-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.coupon-header {
  font-weight: bold;
}

.discount-text {
  color: var(--v-success-base);
}

@media screen and (max-width: 899px) {
  .summary-header {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 599px) {
  .checkout-button {
    font-size: 0.875rem !important;
  }

  .checkout-button {
    justify-content: space-between;
  }

  .button-container {
    padding: 40px 0px 0px;
  }

  .order-table td {
    font-size: 0.75rem;
  }

  .checkout-total td {
    font-size: 0.875rem;
  }
}
</style>
