<template>
  <v-app>
    <main-nav />
    <v-progress-linear
      v-if="$auth.loading"
      indeterminate
      color="primary"
    />
    <error-snackbar
      v-model="errorSnackbar"
      :error-label="$t('serverError')"
      :error-message="errorMessage"
    />
    <v-main>
      <v-container class="pa-0 main-container">
        <v-breadcrumbs
          v-if="breadCrumbs"
          class="bread-crumbs no-print"
          :items="breadCrumbs"
          divider=">"
          large
        />
        <auth-loading v-if="$auth.loading" />
        <router-view v-else />
      </v-container>
    </v-main>
    <ekitabu-footer />
    <MobileNav />
    <claimed-order-modal />
  </v-app>
</template>

<script>
import MainNav from "@/components/navigation/MainNav";
import ErrorSnackbar from "@/components/ErrorSnackbar";
import { mapGetters, mapMutations } from "vuex";
import AuthLoading from "@/components/AuthLoading";
import EkitabuFooter from "@/components/footer/EkitabuFooter";
import GetUserRegionMixin from "@/mixins/GetUserRegionMixin";
import ClaimedOrderModal from "@/components/ClaimedOrderModal";
import MobileNav from "@/components/navigation/MobileNav.vue";

export default {
  name: "App",
  components: {
    MainNav,
    ErrorSnackbar,
    AuthLoading,
    EkitabuFooter,
    ClaimedOrderModal,
    MobileNav,
  },
  mixins: [
    GetUserRegionMixin,
  ],
  data() {
    return {
      errorSnackbar: false,
    };
  },
  computed: {
    ...mapGetters("serverError", ["errorCode", "errorMessage"]),
    ...mapGetters("siteSettings", ["showMobileNav"]),
    breadCrumbs() {
      if (typeof this.$route.meta.breadCrumbs === "function") {
        return this.$route.meta.breadCrumbs.call(this, this.$route);
      }
      return this.$route.meta.breadCrumbs;
    },
  },
  watch: {
    errorSnackbar(newValue, oldValue) {
      if (newValue == false && oldValue == true) {
        setTimeout(() => {
          this.setErrorCode("");
          this.setErrorMessage("");
        }, 300);
      }
    },
    errorMessage(newErrorMessage) {
      if (newErrorMessage) {
        this.errorSnackbar = true;
      }
    },
    errorCode(newErrorCode) {
      if (newErrorCode) {
        this.errorSnackbar = true;
      }
    },
  },
  methods: {
    ...mapMutations("serverError", ["setErrorCode", "setErrorMessage"]),
  },
};
</script>

<style scoped lang="scss">
@import "~vuetify/src/styles/styles.sass";

.main-container.container {
  max-width: $max-content-width;
}

.bread-crumbs {
  height: 30px;
}

::v-deep .v-breadcrumbs.bread-crumbs .v-breadcrumbs__item,
::v-deep .v-breadcrumbs.bread-crumbs .v-breadcrumbs__divider {
  color: var(--v-primaryText-base);
}

::v-deep .v-breadcrumbs.bread-crumbs .v-breadcrumbs__item:hover {
  text-decoration: underline;
}

::v-deep .v-breadcrumbs.bread-crumbs > li:last-of-type > .v-breadcrumbs__item{
  color: var(--v-primary-base);
}
</style>
