<template>
  <div class="data-table-header-container px-6 px-md-6 mb-6">
    <slot name="default"/>
  </div>
</template>

<script>
export default {
  name: "DataTableHeaderContainer",
};
</script>